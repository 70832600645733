// src/components/AnimationManager.tsx
import React, { SetStateAction } from 'react';
import { motion } from 'framer-motion';

import MarkerComponent from './MarkerComponent';

interface SliderComponentProps {
  lineColour: string;
  iconColour: string;
  textColour: string;
  leftValue: number;
  rightValue: number; // The numbers at each end
  leftWobbling: boolean; // The wobbling states
  rightWobbling: boolean;
  leftWobblingUpdater: React.Dispatch<SetStateAction<boolean>>; // wobbling state updaters
  rightWobblingUpdater: React.Dispatch<SetStateAction<boolean>>;
}

const SliderComponent: React.FC<SliderComponentProps> = (
  { lineColour, iconColour, textColour, leftValue, rightValue, leftWobbling, rightWobbling, leftWobblingUpdater, rightWobblingUpdater }
  ) => {

    const wobbleAnimation = {
      scale: [1, 1.05, 1, 1.05, 1], // scale up and down
      rotate: [0, 5, -5, 5, 0], // rotate back and forth
      transition: {
          duration: 0.5, // duration of the whole animation
      }
    };

  
  return (
    <div className="slider-container" style={{display: "flex-col", height: "24dvh", alignContent: "center", justifyContent: "center", width: 'calc(80dvh * 500 / 844)'}}>
      <div className="spacer" style={{height: "6dvh"}}></div>
      <div className="marker-container-row" style={{display: "flex", justifyContent: "space-between", position: "relative"}}>
      <div className="marker-container" style={{display: "flex", flexDirection: "column"}}>
          <b style={{color:textColour}}>{leftValue}</b>
          <div className="spacer" style={{height: "2dvh"}}></div>
          <motion.div 
            animate={leftWobbling ? wobbleAnimation : ""}
            onAnimationComplete={() => leftWobblingUpdater(false)}
            >
              <MarkerComponent compid="leftmarker" markerHeight={10} markerWidth={6} markerUnits="dvh" iconColour={iconColour}/>
          </motion.div>
        </div>
        <div className="marker-container" style={{display: "flex", flexDirection: "column"}}>
          <b style={{color:textColour}}>{rightValue}</b>
          <div className="spacer" style={{height: "2dvh"}}></div>
          <motion.div 
            animate={rightWobbling ? wobbleAnimation : ""}
            onAnimationComplete={() => rightWobblingUpdater(false)}
            >
              <MarkerComponent compid="rightmarker" markerHeight={10} markerWidth={6} markerUnits="dvh" iconColour={iconColour}/>
          </motion.div>
          
        </div>
      </div>
      <div className="linecontainer" style={{width: "100%"}}>
        <div className="tline" style={{marginLeft: "3dvh", marginRight: "3dvh", height: "1px", backgroundColor:lineColour}}></div>
      </div>
    </div>
  );
};

export default SliderComponent;