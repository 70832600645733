import { motion } from 'framer-motion';

import MarkerComponent from './MarkerComponent';

// Define a type for the position
type Position = {
  x: number;
  y: number;
};

// Props type for the FloatingComponent
type FloatingComponentProps = {
  iconColour: string;
  isVisible: boolean;
  startPosition: Position;
  endPosition: Position;
};

const FloatingComponent: React.FC<FloatingComponentProps> = ({ iconColour, isVisible, startPosition, endPosition }) => {
  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0, left: startPosition.x, top: startPosition.y }} // Initial state: hidden at the top-left
      animate={{
        opacity: [ 1, 1],
        left: [startPosition.x, endPosition.x],
        top: [startPosition.y, endPosition.y]
      }}
      transition={{
        type: "tween", duration: 1.5, times: [0.1, 1], ease: "anticipate"
      }}
      style={{
        position: 'absolute',
        zIndex: 2,
      }}
    >
      <MarkerComponent compid="floatingmarker" markerHeight={10} markerWidth={6} markerUnits="dvh" iconColour={iconColour}/>
    </motion.div>
  );
};

export default FloatingComponent;
export type { Position };