// date utility functions

// convert a date to correct format
export function toDateString(date: Date): string {
    const dateString: string = (
      date.getFullYear() + '-' + 
      String(date.getMonth() + 1).padStart(2, '0') + '-' + 
      String(date.getDate()).padStart(2, '0'));
    return dateString
}

// difference in days function
export function dayDiff(todaysDateString: string, gameDateString: string): number {
    const lastPlayedDate: Date = new Date(gameDateString);
    const todaysDate: Date = new Date(todaysDateString);
    const differenceInMilliseconds = Math.abs(todaysDate.getTime() - lastPlayedDate.getTime());
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.round(differenceInDays)
}