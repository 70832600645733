// questions and answers
const questionsAndAnswers: string[][] = [
    [
        "In what year did the final episode of 'M*A*S*H' air, becoming the most-watched TV episode in history?",
        "1983"
    ],
    [
        "In what year did R\u00f6ntgen discover X-rays?",
        "1895"
    ],
    [
        "In what year was the Mediterranean sea connected to the Red Sea via the opening of the Suez Canal?",
        "1869"
    ],
    [
        "In what year were Pompeii and Herculaneum buried by the eruption of Mount Vesuvius?",
        "79"
    ],
    [
        "In what year did Leonardo da Vinci start painting the Mona Lisa?",
        "1503"
    ],
    [
        "In what year did Simone Biles win four gold medals at the Rio Olympics?",
        "2016"
    ],
    [
        "In what year did the Mayflower land at Plymouth Rock?",
        "1620"
    ],
    [
        "In what year was the United States Declaration of Independence signed?",
        "1776"
    ],
    [
        "In what year was the Ford Model T introduced?",
        "1908"
    ],
    [
        "In what year was ENIAC, the first general-purpose electronic computer, unveiled?",
        "1946"
    ],
    [
        "In what year did the Battle of Stalingrad begin?",
        "1942"
    ],
    [
        "In what year was the film '2001: A Space Odyssey' released?",
        "1968"
    ],
    [
        "In what year did Queen Victoria ascend the throne of the United Kingdom?",
        "1837"
    ],
    [
        "In what year did the Chernobyl nuclear disaster occur in the Soviet Union?",
        "1986"
    ],
    [
        "In what year did Usain Bolt break the 100m world record at the Beijing Olympics?",
        "2008"
    ],
    [
        "In what year did New Zealand become the first country to grant women the right to vote?",
        "1893"
    ],
    [
        "In what year did Charles Martel defeat the Moors at the Battle of Tours, halting their advance into Europe?",
        "732"
    ],
    [
        "In what year did the Lisbon earthquake devastate the Portuguese capital?",
        "1755"
    ],
    [
        "In what year was Charles V elected Holy Roman Emperor?",
        "1519"
    ],
    [
        "In what year did Augustus, the first Roman emperor, die?",
        "14"
    ],
    [
        "In what year did Thomas Edison invent the phonograph?",
        "1877"
    ],
    [
        "In what year was the first 'Ghostbusters' film released?",
        "1984"
    ],
    [
        "In what year did NASA's New Horizons spacecraft complete its flyby of Pluto?",
        "2015"
    ],
    [
        "In what year did the Soviet Union invade Afghanistan?",
        "1979"
    ],
    [
        "In what year was the firs iPhone released?",
        "2007"
    ],
    [
        "In what year was the French monarchy abolished, and the First French Republic declared?",
        "1792"
    ],
    [
        "In what year did Pope Urban II call the First Crusade?",
        "1096"
    ],
    [
        "In what year was the East India Company established in England?",
        "1600"
    ],
    [
        "In what year did Napoleon's invasion of Russia lead to his disastrous retreat from Moscow?",
        "1812"
    ],
    [
        "In what year was the Royal Society founded in London?",
        "1660"
    ],
    [
        "In what year did John Snow discover the link between cholera and water supply?",
        "1854"
    ],
    [
        "In what year did the New Deal begin under Franklin D. Roosevelt?",
        "1933"
    ],
    [
        "In what year was the Act of Union passed, uniting England and Scotland into Great Britain?",
        "1707"
    ],
    [
        "In what year did The Beatles announce their breakup?",
        "1970"
    ],
    [
        "In what year did 'The Silence of the Lambs' win Best Picture at the Oscars?",
        "1992"
    ],
    [
        "In what year was Facebook launched by Mark Zuckerberg?",
        "2004"
    ],
    [
        "In what year did NASA's Curiosity rover land on Mars?",
        "2012"
    ],
    [
        "In what year was the League of Nations founded?",
        "1920"
    ],
    [
        "In what year did Elvis Presley record his first single, 'That's All Right'?",
        "1954"
    ],
    [
        "In what year did the Yom Kippur War take place?",
        "1973"
    ],
    [
        "In what year was Nelson Mandela released from prison after 27 years?",
        "1990"
    ],
    [
        "In what year did Serena Williams win her first Grand Slam title?",
        "1999"
    ],
    [
        "In what year did World War I end with the signing of the Armistice?",
        "1918"
    ],
    [
        "In what year was the film 'Casablanca,' starring Humphrey Bogart, released?",
        "1942"
    ],
    [
        "In what year did Yuri Gagarin become the first human in space?",
        "1961"
    ],
    [
        "In what year was the first human heart transplant performed by Dr. Christiaan Barnard?",
        "1967"
    ],
    [
        "In what year was the film 'The Godfather' released?",
        "1972"
    ],
    [
        "In what year was Louise Brown, the first person to be conceived via IVF, born?",
        "1978"
    ],
    
    [
        "In what year was the first successfully cloned mammal, Dolly the sheep, born?",
        "1996"
    ],
    [
        "In what year did NATO intervene in Kosovo during the Yugoslav Wars?",
        "1999"
    ]
]
export default questionsAndAnswers;