// css marker component
import React from 'react';


interface MarkerComponentProps {
  iconColour: string;
  compid: string;
  markerHeight: number;
  markerWidth: number;
  markerUnits: string;
}


const MarkerComponent: React.FC<MarkerComponentProps> = (
  {iconColour, compid, markerHeight, markerWidth, markerUnits}
  ) => {

  const boxHeight: string = String(markerHeight) + markerUnits;
  const boxWidth: string = String(markerWidth) + markerUnits;

  const tryYOffsetRatio: number = 0.5;
  const triYOffset: number = (markerWidth / 2) * (1 + tryYOffsetRatio);
  const triHeight: number = markerHeight - triYOffset;
  const gap: number = tryYOffsetRatio * (markerWidth/2)

  const halfWidth: number = Math.sqrt((markerWidth/2)**2 - (gap)**2);
  const triXOffset: number = (markerWidth - (2 * halfWidth))/2;

  const triBorderTop: string = String(triHeight) + markerUnits + " solid " + iconColour;
  const triBorderLeft: string = String(halfWidth) + markerUnits + " solid transparent";
  const triBorderRight: string = String(halfWidth) + markerUnits + " solid transparent";
  const triXOffsetU: string = String(triXOffset) + markerUnits;
  const triYOffsetU: string = String(triYOffset) + markerUnits;

  
  return (
    <div>
      <div
      id={compid}
      className="MarkerComponent"
      style={{position: "relative", height: boxHeight, width: boxWidth}}
      >
        <div
        className="circle"
        style={{position: "absolute", width: boxWidth, height: boxWidth, borderRadius: "50%", backgroundColor: iconColour}}>
        </div>
        <div
        className="triangle"
        style={{position: "absolute", top: triYOffsetU, left: triXOffsetU, borderTop: triBorderTop, borderLeft: triBorderLeft, borderRight: triBorderRight}}>
        </div>
      </div> 
    </div>
  );
};

export default MarkerComponent;