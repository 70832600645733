// graph component
import React from 'react';

type GraphProps = {
  scores: number[];
  barColour: string;
  textColour: string;
  borderColour: string;
};

const GraphComponent: React.FC<GraphProps> = ({ scores, barColour, textColour, borderColour }) => {
  const maxScore = Math.max(...scores.slice(0, -1));
  console.log(scores);
  console.log(maxScore);
  const labels = ["1", "2", "3", "4", "5", "10"];
  const guessLabels = ["Guessed in:", "", "", "", "Guess within:", ""];
  const guessLabelWidth = guessLabels.reduce((max, label) => Math.max(max, label.length), 0) * 0.5 + 'em';
  const borderStyle: string = "1px solid " + borderColour;

  return (
    <div style={{ fontSize: '0.9rem' }}>
      <table style={{ width: '100%', borderCollapse: 'separate'}}>
        <tbody>
          {labels.map((label, index) => (
            <tr key={label} style={{ color: textColour }}>
              <td style={{ border: 'none',  textAlign: "left", minWidth: guessLabelWidth, whiteSpace: 'nowrap'}}>
                {index < guessLabels.length ? guessLabels[index] : ''}
              </td>
              <td style={{ border: 'none', padding: "0.3em", borderRight: borderStyle}}>
                {label}
              </td>
              <td style={{ border: 'none', width: '50px', textAlign: 'center'}}>
                {scores[index]}
              </td>
              <td style={{padding: '0.1rem', width: '100%' }}>
                <div
                  style={{
                    backgroundColor: barColour,
                    width: `${(scores[index] / maxScore) * 100}%`,
                    height: '20px'
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GraphComponent;