// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import GuessContainer from './components/GuessContainer';
import Header from './components/Header';
import SliderComponent from './components/SliderComponent';
import Keyboard from './components/Keyboard';
import Question from './components/Question';
import FloatingComponent, { Position } from './components/FloatingComponent';
import FloatingStaticComponent from './components/FloatingStaticComponent';
import SettingsDialogue from './components/SettingsDialogue';
import StatsDialogue from './components/StatsDialogue';
import AnswerComponent from './components/AnswerComponent';
// styles
import './App.css';

import { GameDataInterface } from './utils/GameData';
import { dayDiff, toDateString } from './utils/DateUtils';
import { guessInRange, guessNotNan, guessValidLength } from './utils/GuessUtils';

import questionsAndAnswers from "./QuestionsAndAnswers"

const today: Date = new Date();
const todayDateString = toDateString(today);
const dbyesterday: Date = new Date(today);
dbyesterday.setDate(dbyesterday.getDate() - 2);

// hard coded variable(s) for launch
const gameStartDateString: string = '2024-08-26';
const gameStartDate: Date = new Date(gameStartDateString);
const numGame: number = Math.floor((today.valueOf() - gameStartDate.valueOf()) / (1000 * 60 * 60 * 24));
const totalGames: number = questionsAndAnswers.length;
const defaultAnswer: number = 1;
const defaultQuestionText: string = "default question goes here?";

// default settings for new games
// game data key
const gameDataKey: string = "iwygamedata";
const numGuesses: number = 4; // Configure the number of guesses here

const startMinYear: number = 0;
const startMaxYear: number = today.getFullYear();  // returns a number type

const finalPositionDefault: Position = { x: 0.5, y: 0.5 };

const defaultData: GameDataInterface = {
  // Other default values
  lastPlayed: toDateString(dbyesterday),
  lastVisited: toDateString(today),
  guesses: Array.from({ length: numGuesses }, () => ""),
  guessed: 0,
  minYear: startMinYear,
  maxYear: startMaxYear,
  streak: 0,
  maxStreak: 0,
  scores: [0, 0, 0, 0, 0, 0, 0],
  done: false,
  finalPosition: finalPositionDefault,
  lightMode: true,
  backgroundColour: "#FFFFFF",
  textColour: "#0F121A",
  buttonColour: "#A1A7BA",
  lineColour: "#454B5E",
  markerColour: "#454B5E",
};

// app
const App: React.FC = () => {

  // set question and answer
  const answer: number = numGame < totalGames ? parseInt(questionsAndAnswers[numGame][1]) : defaultAnswer;
  const questionText: string = numGame < totalGames ? questionsAndAnswers[numGame][0] : defaultQuestionText;

  // dialogue openers
  const [settingsDialogueOpen, setSettingsDialogueOpen] = useState<boolean>(false);
  const [statsDialogueOpen, setStatsDialogueOpen] = useState<boolean>(false);

  // marker component initial visibility and position
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [startPosition, setStartPosition] = useState<Position>({ x: 0.5, y: 0.5 });
  const [endPosition, setEndPosition] = useState<Position>({ x: 0.5, y: 0.5 });

  // marker interactions
  const [leftWobbling, setLeftWobbling] = useState<boolean>(false);
  const [rightWobbling, setRightWobbling] = useState<boolean>(false);

  // helper popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const pid = open ? 'simple-popover' : undefined;

  const onOutOfBounds = () => {
    const guessContainerElement = document.getElementById("guesscontainer")
    setAnchorEl(guessContainerElement!);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1000); // Popover will close after 1 second(s)
  };

  const handleColourToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    // 'checked' is true if the switch is on, false if it's off
    if (checked) {
      // Light mode activated
      setGameData(prevGameData => ({
        ...prevGameData,
        lightMode: true,
        backgroundColour: "#FFFFFF",
        textColour: "#0F121A",
        buttonColour: "#A1A7BA",
        lineColour: "#454B5E",
        markerColour: "#454B5E",
      }));
    } else {
      // Dark mode activated
      setGameData(prevGameData => ({
        ...prevGameData,
        lightMode: false,
        backgroundColour: "#0F121A",
        textColour: "#E6F1E4",
        buttonColour: "#454B5E",
        lineColour: "#454B5E",
        markerColour: "#E6F1E4",
      }));
    }
  };

  // get or set the game data
  const [gameData, setGameData] = useState<GameDataInterface>(() => {
    const storedData = localStorage.getItem(gameDataKey);
    if (storedData) {
      console.log('loaded game data');
      const tempGameData: GameDataInterface = JSON.parse(storedData);
      // check to see whether it is today's date and if not clear the guesses, guessed, and year bounds
      if (tempGameData.lastVisited !== todayDateString) {
        tempGameData.lastVisited = toDateString(today);
        tempGameData.guesses = Array.from({ length: numGuesses }, () => "");
        tempGameData.guessed = 0;
        tempGameData.minYear = startMinYear;
        tempGameData.maxYear = startMaxYear;
        tempGameData.done = false
        // extra check to see whether two days have passed and reset streak to zero
        console.log(dayDiff(todayDateString, tempGameData.lastPlayed));
        if (dayDiff(todayDateString, tempGameData.lastPlayed) > 1) {
          tempGameData.streak = 0;
        }
        }
        const tempGameDataString: string = JSON.stringify(tempGameData);
        return JSON.parse(tempGameDataString);
      } else {
      console.log('created default game data');
      setSettingsDialogueOpen(true);
      return defaultData;
    }
  });
  // update local storage when the state changes
  useEffect(() => {
    console.log("saved game data");
    localStorage.setItem(gameDataKey, JSON.stringify(gameData))
  }, [gameData]);
  
  // updating functions for the state
  // guesses
  const updateGuesses = (newGuesses: string[]) => {
    setGameData({...gameData, guesses: newGuesses});
  };
  // guessed
  const updateGuessed = (newGuessed: number) => {
    setGameData({...gameData, guessed: newGuessed});
  };

  // waiting function
  function wait(milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  function getBoundMarkerPositions(): number[] {
    const leftMarkerElement = document.querySelector('#leftmarker');
    const rightMarkerElement = document.querySelector('#rightmarker');
    if (leftMarkerElement && rightMarkerElement) {
      const leftRect = leftMarkerElement.getBoundingClientRect();
      const rightRect = rightMarkerElement.getBoundingClientRect();
      console.log('succesfully found markers');
      // [leftX, leftY, rightX, rightY]
      return [leftRect.left, leftRect.top, rightRect.left, rightRect.top]
    } else {
      // [bottom, top, left, right]
      console.log('default position')
      return [100, 100, 100, 100]
    }
  };

  function getMarkerPosition(
    percentage: number, leftRectLeft: number, leftRectTop: number,
    rightRectLeft: number, rightRectTop: number): number[] {
      const newX = leftRectLeft + (rightRectLeft - leftRectLeft) * percentage;
      const newY = leftRectTop + (rightRectTop - leftRectTop) * percentage;
      return [newX, newY]
  };
    
  async function onEnd(how: string): Promise<void> {
    console.log("end sequence initiated!")
    const finalGuess: number = parseInt(gameData.guesses[gameData.guessed]);
    const finalPositionPct = (answer - gameData.minYear) / (gameData.maxYear - gameData.minYear);
    const [leftRectLeft, leftRectTop, rightRectLeft, rightRectTop] = getBoundMarkerPositions();
    const [finalX, finalY] = getMarkerPosition(finalPositionPct, leftRectLeft, leftRectTop, rightRectLeft, rightRectTop);
    const finalPosition: Position = {x: finalX, y: finalY};
    const ansDiff: number = Math.abs(finalGuess - answer);
    let newScoreIdx: number;
    if (how === "win") {
      setLeftWobbling(true);
      setRightWobbling(true);
      if (ansDiff === 0) {
        newScoreIdx = gameData.guessed;
      } else {
        newScoreIdx = 6;
      }
    } else {
      if (ansDiff < 6) {
        newScoreIdx = 4;
      } else if (ansDiff < 11) {
        newScoreIdx = 5;
      } else {
        newScoreIdx = 6;
    }}
    setGameData(prevGameData => ({
      ...prevGameData,
      lastPlayed: toDateString(today),
      streak: prevGameData.streak + 1,
      maxStreak: prevGameData.streak + 1 > prevGameData.maxStreak ? prevGameData.streak + 1 : prevGameData.maxStreak,
      scores: prevGameData.scores.map((item, index) => index === newScoreIdx ? item + 1 : item),
    }));
    await wait(1200); // wait for the animations to finish
    setGameData(prevGameData => ({
      ...prevGameData,
      done: true,
      finalPosition: finalPosition,
    }));
    await wait(800); // give time for final marker to appear
    setStatsDialogueOpen(true);
  }
  // TODO: how to gif

  const showFloatingComponent = (newPosition: number, where: string) => {
    // function calculateMarkerPosition():
    const [leftRectLeft, leftRectTop, rightRectLeft, rightRectTop] = getBoundMarkerPositions();
    const [newX, newY] = getMarkerPosition(newPosition, leftRectLeft, leftRectTop, rightRectLeft, rightRectTop);
    setStartPosition({ x: newX, y: newY });
    if (where === "left") {
      setEndPosition({ x: leftRectLeft, y: leftRectTop });
    } else if (where === "right") {
      setEndPosition({ x: rightRectLeft, y: rightRectTop });
    } else {
      setEndPosition({ x: newX, y: newY });
    }
    setIsVisible(true);
  };

  // evaluates the guess
  // controller for the virtual keyboard
  async function handleKeyClick (key: string): Promise<void> {
    // store previous state
    // let previousState: GameDataInterface = JSON.parse(JSON.stringify(gameData));
    const currentGuesses = gameData.guesses;
    const currentGuessed = gameData.guessed;
    const currentGuess = currentGuesses[currentGuessed];
    const intCurrentGuess = parseInt(currentGuess);
    // Handle user input from the virtual keyboard
    // --------------------------------------------------------------------------------------------
    // when the back key is pressed
    if (gameData.done) {
      console.log("no more clicks today")
    } else {
      if (key === '⌫') {
        const updatedGuesses = [...gameData.guesses];
        updatedGuesses[gameData.guessed] = currentGuess.slice(0, -1);
        updateGuesses(updatedGuesses);
      // --------------------------------------------------------------------------------------------
      // when the enter key is pressed
      } else if (key === 'Enter') {
        // if it is the first guess, we need allow the extended range
        if (guessInRange(currentGuess, gameData.minYear, gameData.maxYear)) {
          const newPosition = (intCurrentGuess - gameData.minYear) / (gameData.maxYear - gameData.minYear);
          if (intCurrentGuess === answer) {
            // win the game
            updateGuessed(gameData.guessed + 1);
            showFloatingComponent(newPosition, "end");
            console.log("you win!")
            onEnd("win");
          } else {
            if (gameData.guessed === 3) {
              // lose the game
              updateGuessed(gameData.guessed + 1);
              showFloatingComponent(newPosition, "end");
              console.log("you lose!");
              onEnd("lose");
            } else {
              if (intCurrentGuess < answer) {
                // normal guess: early guess
                setGameData(prevGameData => ({
                  ...prevGameData,
                  guessed: prevGameData.guessed + 1
                }));
                showFloatingComponent(newPosition, "left");
                await wait(1450);
                setIsVisible(false);
                setGameData(prevGameData => ({
                  ...prevGameData,
                  minYear: intCurrentGuess,
                }));
                await wait(100);  // bug fix
              } else {
                // normal guess: late guess
                setGameData(prevGameData => ({
                  ...prevGameData,
                  guessed: prevGameData.guessed + 1
                }));
                showFloatingComponent(newPosition, "right");
                await wait(1450);
                setIsVisible(false);
                setGameData(prevGameData => ({
                  ...prevGameData,
                  maxYear: intCurrentGuess,
                }));
                await wait(100);  // bug fix
              }
            }
          }
        } else {
          if (guessNotNan(currentGuess)) {
            if (intCurrentGuess <= gameData.minYear) {
              // wobble left marker
              setLeftWobbling(true);
              onOutOfBounds();
            } else {
              // wobble right marker
              setRightWobbling(true);
              onOutOfBounds();
            }
          } else {
            console.log("enter a number!");
          }
        }
      // --------------------------------------------------------------------------------------------
      // any number key is pressed
      } else if (key === "0" && currentGuess.length === 0) {
        console.log("no preceeding zeros")
      } else {
        if (guessValidLength(currentGuess)) {
          const updatedGuesses = [...gameData.guesses];
          updatedGuesses[gameData.guessed] = currentGuess + key;
          updateGuesses(updatedGuesses);
        } else {
          console.log("string too long.")
        }
      }
      // --------------------------------------------------------------------------------------------
    }
  }
  // heights
  // header: 7dvh | 7dvh
  // question: 17dvh | 24dvh
  // guess boxes: 32dvh | 56dvh
  // slider marings: 14dvh | 70dvh
  // slider comp: 10dvh | 80dvh
  // keyboard: 20dvh

  return (
    <div className="App" style={{backgroundColor: gameData.backgroundColour, minHeight: "100%", minWidth: "100%", maxHeight:"100%", maxWidth:"100%"}}>
      <Header textColour={gameData.textColour} iconColour={gameData.textColour} dividerColour={gameData.lineColour} setSettingsDialogueState={setSettingsDialogueOpen} setStatsDialogueState={setStatsDialogueOpen}/>
      <div style={{display: "flex", width: '80%', height: '17dvh', justifyContent:"center", alignItems:"center", fontSize:"3dvh", maxWidth: "100%"}}>
        <Question text={questionText} colour={gameData.textColour}/>
      </div>
      <GuessContainer textColour={gameData.textColour} lineColour={gameData.lineColour} guesses={gameData.guesses}/>
      <AnswerComponent content={answer.toString()} colour={"red"} isVisible={gameData.done && (parseInt(gameData.guesses[gameData.guessed-1]) !== answer)}/>
      <SliderComponent
      lineColour={gameData.lineColour}
      iconColour={gameData.buttonColour}
      textColour={gameData.textColour}
      leftValue={gameData.minYear} 
      rightValue={gameData.maxYear}
      leftWobbling={leftWobbling}
      rightWobbling={rightWobbling}
      leftWobblingUpdater={setLeftWobbling}
      rightWobblingUpdater={setRightWobbling}
      />
      <Keyboard onKeyClick={handleKeyClick} buttonColour={gameData.buttonColour} textColour={gameData.textColour}/>
      <FloatingComponent isVisible={isVisible} startPosition={startPosition} endPosition={endPosition} iconColour={gameData.markerColour}/>
      <FloatingStaticComponent 
      compId={'endmarker'} isVisible={gameData.done} 
      startPosition={gameData.finalPosition} 
      iconColour={parseInt(gameData.guesses[gameData.guessed-1]) === answer ? "green" :  "red"}/>
      <SettingsDialogue
      dialogueOpen={settingsDialogueOpen} setDialogueState={setSettingsDialogueOpen}
      backgroundColour={gameData.backgroundColour} objectColour={gameData.lineColour} textColour={gameData.textColour}
      onColourToggle={handleColourToggle} lightModeOn={gameData.lightMode}
      />
      <StatsDialogue 
      dialogueOpen={statsDialogueOpen} setDialogueState={setStatsDialogueOpen}
      backgroundColour={gameData.backgroundColour} objectColour={gameData.lineColour} textColour={gameData.textColour}
      gameData={gameData} answer={answer} gameNo={numGame+1}
      />
      <Popover
        id={pid}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{paddingTop: "1dvh", paddingBottom: "1dvh", paddingRight: "2dvh", paddingLeft: "2dvh",fontSize:"0.9rem", color:gameData.backgroundColour, backgroundColor: gameData.textColour, border:"none", boxShadow:"none"}}>
          {
            "Guess not in range!"
          }
        </div>
        
      </Popover>
    </div>
  );
};

export default App;