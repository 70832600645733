// SettingsDialogue
import React, { SetStateAction } from 'react';
import Switch from '@mui/material/Switch';

import gifImage from '../assets/instructiongifslow.gif'

type SettingsDialogProps = {
  dialogueOpen: boolean;
  setDialogueState: React.Dispatch<SetStateAction<boolean>>;
  backgroundColour: string;
  textColour: string;
  objectColour: string;
  onColourToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  lightModeOn: boolean;
};

const SettingsDialogue: React.FC<SettingsDialogProps>= ({ dialogueOpen, setDialogueState, backgroundColour, textColour, objectColour, onColourToggle, lightModeOn }) => {

  const bgColor = `rgba(0, 0, 0, 0.5)`;

  //const openMailClient = () => {
  //  const email = "example@example.com"; // Replace with your desired email address
  //  const subject = encodeURIComponent("In What Year: feedback");
  //  const body = encodeURIComponent("Body of the email");
  //
  //  const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
  //  window.open(mailtoLink, '_blank');
  //};

  if (dialogueOpen) {
    return (
      <div style={{position: "fixed", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", zIndex: 10, backgroundColor:bgColor, height: "100dvh", width: "100dvw"}}>
        <div style={{position: "fixed", backgroundColor:backgroundColour, height: "95dvh", width: "calc(90dvh * 500 / 844)", border:"none", borderRadius: "5px"}}>
          <div style={{position: "relative", display: "flex", flexDirection: "column", margin:"2dvh"}}>
            <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"flex-end"}}>
              <button onClick={() => setDialogueState(false)} style={{ float: 'right', color: textColour, backgroundColor: backgroundColour, boxShadow: "none", border:"none", fontWeight: "bold", width:"5dvh", height:"5dvh", fontSize: "3dvh"}}>&#10005;</button>
            </div>
            <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"flex-start"}}>
              <div style={{fontSize:"2.5dvh", fontWeight: "bold", color:textColour}}>How to play</div>
            </div>
            <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"flex-start", alignItems: "start"}}>
              <div style={{fontSize:"2dvh", color:textColour, textAlign: "left"}}>
                <ul style={{position: "relative", paddingLeft: "2dvh"}}>
                  <li style={{paddingBottom: "0.5dvh"}}>Guess the year of the event.</li>
                  <li style={{paddingBottom: "0.5dvh"}}>You have four guesses.</li>
                  <li style={{paddingBottom: "0.5dvh"}}>Each guess must be within the bounds.</li>
                  <li style={{paddingBottom: "0.5dvh"}}>The bounds update after each guess.</li>
                  <li style={{paddingBottom: "0.5dvh"}}>Use the backspace key to delete a number.</li>
                  <li style={{paddingBottom: "0.5dvh"}}>Submit a guess with the enter key.</li>
                </ul>
              </div>
            </div>
            <div className="gif-container">
              <img src={gifImage} alt="description" className="gif-image" />
            </div>
            <div style={{height:"2dvh"}}></div>
            <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"flex-start"}}>
              <div style={{fontSize:"2.5dvh", fontWeight: "bold", color:textColour}}>Settings</div>
            </div>
            <div style={{height:"1dvh"}}></div>
            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
              <div style={{fontSize:"2dvh", color:textColour}}>Light mode: </div>
              <Switch checked={lightModeOn} onChange={onColourToggle} />
            </div>
            
            
            <div style={{height:"1dvh"}}></div>
          </div>  
        </div>   
      </div>
    )
  } else {
    return <div></div>
  }
}

export default SettingsDialogue;
// <div style={{display: "flex", flexDirection: "row", justifyContent:"flex-start"}}>
// <div style={{fontSize:"2.5dvh", fontWeight: "bold", color:textColour}}>Contact</div>
// </div>
// <div style={{height:"1dvh"}}></div>
// <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
// <div style={{fontSize:"2dvh", color:textColour}}>Feedback: </div>
// <button onClick={() => openMailClient()} style={{color: textColour, backgroundColor: backgroundColour, textDecoration: "underline", boxShadow: "none", fontSize: "2dvh", border: "none"}}>email</button>
// </div>
// TODO: fix this.