// src/components/Keyboard.tsx

import React from 'react';

interface KeyboardProps {
  buttonColour: string;
  textColour: string;
  onKeyClick: (key: string) => void;
}

const Keyboard: React.FC<KeyboardProps> = ({ buttonColour, textColour, onKeyClick }) => {
  const keyboardLayout = [
    ['1', '2', '3', '4', '5', '6'],
    ['Enter', '7', '8', '9', '0', '⌫'],
  ];

  const handleKeyClick = (key: string) => {
      onKeyClick(key);
  };

  return (
    <div className="keyboard" style={{height: "20dvh", paddingTop: "0dvh", paddingBottom: "0dvh", width: 'calc(100dvh * 500 / 844)', maxWidth: "100%"}}>
      {keyboardLayout.map((row, rowIndex) => (
        <div key={rowIndex} className="keyboard-row" style={{height: "8.5dvh", paddingTop: "1dvh", paddingBottom: "0.5dvh"}}>
          {row.map((key) => (
            <button 
            className="key-button"
            key={key} onClick={() => handleKeyClick(key)}
            style={{fontSize: "1em", padding: "10px 20px", marginLeft: "2px", marginRight: "2px", color:textColour, fontWeight: "bold", backgroundColor: buttonColour, boxShadow: "none", borderRadius:"5px", border:"none"}}>
              {key}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Keyboard;
