// src/components/Question.tsx
import React from 'react';

interface QuestionProps {
  text: string,
  colour: string,
}

const Question: React.FC<QuestionProps> = ({ text, colour }) => {
  return (
      <b style={{marginBottom: "auto", marginTop:"auto", color:colour, maxWidth: "100%"}}>{ text }</b>
  );
};

export default Question;
