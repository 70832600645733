// src/components/Header.tsx
import React, { SetStateAction } from 'react';
// get our fontawesome imports
import { faBars, faSquarePollHorizontal} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface HeaderProps {
  textColour: string;
  iconColour: string;
  dividerColour: string;
  setSettingsDialogueState: React.Dispatch<SetStateAction<boolean>>;
  setStatsDialogueState: React.Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = (
  {
    textColour,
    iconColour,
    dividerColour,
    setSettingsDialogueState,
    setStatsDialogueState,
  }) => {
  
  const borderStyle: string = "1px solid " + dividerColour;

  return (
    <div
    className="HeaderContainer"
    style={{display: "flex", flexDirection:"column", alignItems: "center", justifyContent:"center", height: "7dvh", width: "100%", borderBottom:borderStyle}}
    >
      <div className="FlexContainer" style={{display: "flex", justifyContent: "space-between", height:"7dvh", width:"100%"}}>
        <div className="ButtonBoxLeft" style={{display:"flex", width: "20%", alignItems: "center", justifyContent: "flex-start"}}>
          <div className="HeaderSpacer" style={{width: "1dvh"}}></div>
        </div>
        <div className="TextBox" style={{display:"flex", width: "60%", textAlign:"center", justifyContent:"center", alignItems:"center", marginTop: "auto", marginBottom:"auto"}}>
          <b style={{verticalAlign: "middle", fontSize:"4dvh", marginBottom: "auto", marginTop:"auto", color: textColour}}>In what year</b>
        </div>
        <div className="ButtonBoxRight" style={{display:"flex", width: "20%", alignItems: "center", justifyContent: "flex-end"}}>
          <FontAwesomeIcon icon={faSquarePollHorizontal} onClick={()=>setStatsDialogueState(true)} style={{height: "6dvh", width: "6dvh", color: iconColour}}/>
          <div className="HeaderSpacer" style={{width: "1dvh"}}></div>
          <FontAwesomeIcon icon={faBars} onClick={()=>setSettingsDialogueState(true)} style={{height: "5dvh", width: "5dvh", color: iconColour}}/>
          <div className="HeaderSpacer" style={{width: "1dvh"}}></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
