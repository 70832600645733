
// Props type for the FloatingComponent
type AnswerComponentProps = {
  content: string;
  isVisible: boolean;
  colour: string
};

const AnswerComponent: React.FC<AnswerComponentProps> = ({ content, isVisible, colour }) => {
  if (!isVisible) return null;

  return (
    <div 
    style={{position: "absolute", left: "50dvw", top: "56dvh", zIndex:3, color: colour, transform: "translate(-50%, -0%)", fontSize: "3dvh", marginTop: "1dvh"}}
    >
      {content}
    </div>
      
  );
};

export default AnswerComponent;