import MarkerComponent from './MarkerComponent';

// Define a type for the position
type Position = {
  x: number;
  y: number;
};

// Props type for the FloatingComponent
type FloatingStaticComponentProps = {
  compId: string;
  iconColour: string;
  isVisible: boolean;
  startPosition: Position;
};

const FloatingStaticComponent: React.FC<FloatingStaticComponentProps> = ({ compId, iconColour, isVisible, startPosition }) => {
  if (!isVisible) return null;

  return (
    <div style={{position: "absolute", left: startPosition.x, top: startPosition.y, zIndex:3}}>
      <MarkerComponent compid={compId} markerHeight={10} markerWidth={6} markerUnits="dvh" iconColour={iconColour}/>
    </div>
      
  );
};

export default FloatingStaticComponent;