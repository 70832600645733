// src/components/GuessContainer.tsx

import React from 'react';

interface GuessContainerProps {
  textColour: string;
  lineColour: string;
  guesses: string[];
}
// the sizing of css is here is quite buggy I need to do it much better throughout the app
const GuessContainer: React.FC<GuessContainerProps> = ({ textColour, lineColour, guesses }) => {
  return (
    <div id="guesscontainer" style={{ display: 'flex', marginLeft: '0dvh', paddingRight: '0dvh', flexDirection: 'column', justifyContent: 'center', width: 'calc(32dvh * 500 / 844)', height: "32dvh", maxWidth: "100%"}}>
      {guesses.map((text, index) => (
        <div 
          key={index}
          style={{ display: "flex", flexDirection: 'column', height: '8dvh', borderBottom: '2px solid #ccc', paddingTop: '1dvh', alignItems: "center", justifyContent: 'center', borderColor:lineColour}}>
          <span style={{fontSize: "4dvh", color:textColour}}>
            {text}
          </span>
        </div>
      ))}
    </div>
  );
};

export default GuessContainer;
