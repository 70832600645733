// StatsDialogue
import React, { SetStateAction } from 'react';

import { UAParser } from 'ua-parser-js';

import GraphComponent from './GraphComponent';
import { GameDataInterface } from '../utils/GameData';
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Popover from '@mui/material/Popover';

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable'];
const parser = new UAParser();
const browser = parser.getBrowser();
const device = parser.getDevice();

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  )
};

type StatsDialogProps = {
  dialogueOpen: boolean;
  setDialogueState: React.Dispatch<SetStateAction<boolean>>;
  backgroundColour: string;
  textColour: string;
  objectColour: string;
  gameData: GameDataInterface;
  answer: number;
  gameNo: number;
};

// Define styles as objects
const gridStyles: { [key: string]: React.CSSProperties } = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '10px',
    alignItems: 'start',
    marginBottom: '20px',
    marginTop: '15px',
  },
};

const shareStatus = (
  textToShare: string
) => {

  const shareData = { text: textToShare};

  let shareSuccess = false;

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData);
      shareSuccess = true;
    }
  } catch (error) {
    shareSuccess = false;
  }

  try {
    if (!shareSuccess) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(textToShare)
          //.then(handleShareToClipboard)
          //.catch(handleShareFailure);
      } else {
        console.log('share error')
    // handleShareFailure();
      }
    }
  } catch (error) {
    console.log('share error')
    // handleShareFailure();
  }
};

function generateShareText(guesses: string[], target: number, gameNo: number): string {
  // Converts a number to its emoji keycap representation

  const topLine: string = `In What Year #${gameNo}`;

  const emojiLines = guesses.map((number, index) => {
      let emoji = "⬜";
      if (parseInt(number) === target) {
        emoji = "✅";
      } else {
        if ((index === 3) && (number !== "")) {
          emoji = "❌";
      } else if (parseInt(number) > target) {
          emoji = "⬆️";
      } else if (parseInt(number) < target) {
          emoji = "⬇️";  
      }
    }
      return emoji;
  });

  return `${topLine}\n\n${emojiLines.join(' ')}`;

};

const StatsDialogue: React.FC<StatsDialogProps>= ({ dialogueOpen, setDialogueState, backgroundColour, textColour, objectColour, gameData, answer, gameNo }) => {

  const bgColor = `rgba(0, 0, 0, 0.5)`;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1000); // Popover will close after 3 seconds

    if (gameData.done) {
      // const textToShare = generateShareText(gameData.guesses, answer);
      const textToShare = generateShareText(gameData.guesses, answer, gameNo);
      console.log(textToShare);
      shareStatus(textToShare);
    } else {
      console.log("Cannot share yet.")
    }
  };

  // stats
  const gamesPlayed: number = gameData.scores.reduce((a, b) => a + b, 0);
  const gamesPlayedSafe: number = gamesPlayed === 0 ? 1 : gamesPlayed;
  const gamesWon: number = gameData.scores.slice(0, -3).reduce((a, b) => a + b, 0);
  const gamesWonOrAlmost: number = gameData.scores.slice(0, -1).reduce((a, b) => a + b, 0);
  const winPercentage: number = Math.round((100 * gamesWon) / gamesPlayedSafe);

  if (dialogueOpen) {
    return (
      <div style={{position: "fixed", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", zIndex: 10, backgroundColor:bgColor, height: "100dvh", width: "100dvw"}}>
        <div style={{position: "fixed", backgroundColor:backgroundColour, height: "95dvh", width: "calc(90dvh * 500 / 844)", border:"none", borderRadius: "5px"}}>
          <div style={{position: "relative", display: "flex", flexDirection: "column", margin:"2dvh"}}>
            <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"flex-end"}}>
              <button onClick={() => setDialogueState(false)} style={{ float: 'right', color: textColour, backgroundColor: backgroundColour, boxShadow: "none", border:"none", fontWeight: "bold", width:"5dvh", height:"5dvh", fontSize: "3dvh"}}>&#10005;</button>
            </div>
              <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"flex-start"}}>
                <div style={{fontSize:"2.5dvh", fontWeight: "bold", color:textColour}}>Player data</div>
              </div>
              {/* score grid */}
              <div style={gridStyles.gridContainer}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                  <div style={{fontSize: "4.5dvh", color: textColour, textAlign: "center"}}>{gamesPlayed === 0? "-" : winPercentage}</div>
                  <div style={{fontSize: "0.9rem", color: textColour, textAlign: "center"}}>Win %</div>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                  <div style={{fontSize: "4.5dvh", color: textColour, textAlign: "center"}}>{gamesPlayed}</div>
                  <div style={{fontSize: "0.9rem", color: textColour, textAlign: "center", alignItems: "end"}}>Games played</div>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                  <div style={{fontSize: "4.5dvh", color: textColour, textAlign: "center"}}>{gameData.streak}</div>
                  <div style={{fontSize: "0.9rem", color: textColour, textAlign: "center"}}>Current streak</div>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                  <div style={{fontSize: "4.5dvh", color: textColour, textAlign: "center"}}>{gameData.maxStreak}</div>
                  <div style={{fontSize: "0.9rem", color: textColour, textAlign: "center"}}>Max streak</div>
                </div>
              </div>
              <div style={{height: "4dvh"}}></div>
              <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"flex-start"}}>
                <div style={{fontSize:"2.5dvh", fontWeight: "bold", color:textColour}}>Score distribution</div>
              </div>
              <div style={{height: "2dvh"}}></div>
              <GraphComponent scores={gameData.scores} textColour={textColour} barColour={gamesWonOrAlmost === 0 ? backgroundColour : textColour} borderColour={objectColour}/>
              <div style={{height: "8dvh"}}></div>
              <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"center"}}>
                <button 
                  style={{paddingTop: "2dvh", paddingBottom: "2dvh", paddingRight: "4dvh", paddingLeft: "4dvh",fontSize:"3dvh", color:backgroundColour, backgroundColor: textColour, border:"none", boxShadow:"none", borderRadius:"12px"}}
                  onClick={onShareClick}
                  >
                  <div style={{position: "relative", display: "flex", flexDirection: "row", justifyContent:"center"}}>
                    <div >Share</div>
                    <div style={{width: "1dvw"}}></div>
                    <FontAwesomeIcon icon={faShareNodes} style={{width: "3dvh", height: "3dvh"}}/>
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <div style={{paddingTop: "1dvh", paddingBottom: "1dvh", paddingRight: "2dvh", paddingLeft: "2dvh",fontSize:"0.9rem", color:backgroundColour, backgroundColor: textColour, border:"none", boxShadow:"none", borderRadius:"12px"}}>
                      {gameData.done ? "copied!" : "finish game to share result"}
                    </div>
                    
                  </Popover>

                </button>
              </div>
            </div>
          </div>  
        </div>   
    )
  } else {
    return <div></div>
  }
}

export default StatsDialogue;