// guessing logic utility functions

// checks whether the guess is a valid date
export function guessInRange(value: string, min: number, max: number): boolean {
    const num = parseInt(value);
    return num > min && num < max && !isNaN(num);
};

// checks whether the guess is a valid string
export function guessValidLength(value: string): boolean {
    if (value.length < 4){
        return true;
    } else {
        return false;
    }
};

// checks whether the guess is a valid date
export function guessNotNan(value: string): boolean {
    const num = parseInt(value);
    return !isNaN(num);
};